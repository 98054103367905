import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const ICON_ID = 'rep_seller_delivery_time_bad_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--time-negative';

const IconTimeNegativeV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="time-negative-v2.svg" alt="" />
  </div>
);

IconTimeNegativeV2.propTypes = {
  className: string,
};

IconTimeNegativeV2.defaultProps = {
  className: null,
};

IconTimeNegativeV2.ICON_ID = ICON_ID;

export default React.memo(IconTimeNegativeV2);
export { IconTimeNegativeV2 };
